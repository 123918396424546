import axios from "axios";
import appConfig from "@/app-config";
import devices from "devextreme/core/devices";
import Keycloak from 'keycloak-js';

export default {
    loggedIn() {
        return !!appConfig.token;
    },

    deviceUUID() {
        if (!localStorage.getItem('device_uuid')) {
            localStorage.setItem('device_uuid', crypto.randomUUID());
        }
        return localStorage.getItem('device_uuid');
    },

    logIn(company, loginName, password) {
        return axios.post(appConfig.apiHost + "login", {
            company: company,
            loginname: loginName,
            pw: password,
            device_uuid: this.deviceUUID(),
            device_type: devices.current(),
        }).then(response => {
            if (response.status === 200) {
                localStorage.setItem('token', response.data.token);
                appConfig.menu = response.data.menu;
                appConfig.userIdent = response.data.user_ident;
                appConfig.userId = response.data.user_id;
                appConfig.userGroupId = response.data.user_group_id;
                appConfig.token = response.data.token;
                appConfig.dashboardReportId = response.data.dashboard_report_id;
                return true;
            }
        }).catch(error => {
            this._error = error;
            return false;
        });
    },

    async logOut() {
        appConfig.menu = null;
        appConfig.userIdent = null;
        appConfig.userId = null;
        appConfig.token = null;
        localStorage.removeItem('token');
    },

    async authenticate() {

        const token = localStorage.getItem('token');
        if (!token) return false;
        return await axios.get(appConfig.apiHost + "authenticate?token=" + token + '&device_uuid=' + this.deviceUUID()).then(response => {
            localStorage.setItem('token', response.data.token);
            appConfig.menu = response.data.menu;
            appConfig.userIdent = response.data.user_ident;
            appConfig.userId = response.data.user_id;
            appConfig.userGroupId = response.data.user_group_id;
            appConfig.token = response.data.token;
            appConfig.dashboardReportId = response.data.dashboard_report_id;
            return response.status === 200;
        }).catch(error => {
            this._error = error;
            return null;
        });
    },

    async authenticateSSO(code) {
        const keycloak = new Keycloak({
            url: 'https://idp.breuni.io',
            realm: 'playground',
            clientId: 'meyer-meyer-oidc-test'
        });

        keycloak.init({flow: 'implicit',
            checkLoginIframe: true,
            onLoad: 'login-required',
            redirectUri: 'https://yard.mo-apps.de'
        }).then((authenticated) => {
            alert(authenticated ? 'authenticated' : 'not authenticated');
            keycloak.logout();
        });
    },



    changePw(pw) {
        return axios.post(appConfig.apiHost + "changePW", {id: appConfig.userId, pw: pw}).then(response => {
            if (response.status === 200) {
                return true;
            }
        }).catch(error => {
            this._error = error;
            return false;
        });
    }
};
