import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";

import App from "./App";
import appInfo from "./app-info";

// import Form from "devextreme/ui/form";
import Config from "devextreme/core/config";
// import DevExpress from "devextreme/data/";

// Config({
//     forceIsoDateParsing: false,
//     editorStylingMode: 'outlined',
// });
//
// Form.defaultOptions({
//     device: [
//         { deviceType: 'desktop' },
//         { deviceType: 'tablet' },
//         { deviceType: 'phone' },
//     ],
//     options: {
//         labelMode: 'static',
//         labelAlign: 'left'
//     }
// });
// import Vue from 'vue';
import { licenseKey } from './devextreme-license';
// import appConfig from "@/app-config";
// import {formatDate} from "devextreme/localization";

Config({ licenseKey });

themes.initialized(() => {
    const app = createApp(App);
    app.use(router);
    app.config.globalProperties.$appInfo = appInfo;
    app.mount('#app');
});
