export default {

    version: '10015',
    language: 'de',

    googleApi: 'AIzaSyBoGEsyYZ30VYxAm7j0vQFS1kD43s-WhlM',

    token: null,
    userId: null,
    userGroupId: null,
    userIdent: null,
    dashboardReportId: null,

    menu: null,

    host: document.location.origin === 'http://localhost:8080' ? 'http://yard/': 'https://yard.halvar-it.com/',
    apiHost: document.location.origin === 'http://localhost:8080' ? 'http://yard/api/': 'https://yard.halvar-it.com/api/',

};

