import auth from "./auth";
import {createRouter, createWebHashHistory} from "vue-router";

import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";

import appConfig from "@/app-config";

import {loadMessages, locale} from "devextreme/localization";
import deMessages from "devextreme/localization/messages/de.json";
import ylviMessages from "@/ylvi_lng.json";

function loadView(view) {
    return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

function addRouteFromMenuItem(items) {
    if (items)
        items.forEach((item) => {
            if (item.path && item.path !== '') {
                router.addRoute({
                    component: item.component ? loadView(item.component) : loadView(item.path.replace('/', '')),
                    name: item.path.replace('/', ''),
                    path: item.path,
                    meta: {
                        requiresAuth: true,
                        layout: defaultLayout,
                    }
                });
            }
            if (item.items) addRouteFromMenuItem(item.items);
        });
}

loadMessages(deMessages);
loadMessages(ylviMessages);
locale(appConfig.language);

let version = localStorage.getItem('version');
if (version !== appConfig.version) {
    let token = localStorage.getItem('token'),
        kcToken = localStorage.getItem('kcToken'),
        kcTokenAt = localStorage.getItem('kcTokenAt');
    localStorage.clear();
    localStorage.setItem('token', token);
    if (kcToken && kcToken !== '')
        localStorage.setItem('kcToken', kcToken);
    if (kcTokenAt && kcTokenAt !== '')
        localStorage.setItem('kcTokenAt', kcTokenAt);
    localStorage.setItem('version', appConfig.version);
}


let uri = window.location.search.substring(1);
let params = new URLSearchParams(uri);

// if (localStorage.getItem('x') !== '1') {
//     localStorage.setItem('x','1');
//     let authendticated = await auth.authenticateSSO();
// }

// if (params.get('code')) {
//     await auth.authenticateSSO(params.get('code'));
// } else if (!localStorage.getItem('kcToken')) {
//     let date = btoa((new Date()).toISOString());
//     window.open('http://localhost:8080/realms/master/protocol/openid-connect/auth?response_type=code&client_id=meyer-meyer-oidc-test' + '&state=' + date, '_self');
    // window.open('https://idp.breuni.io/realms/playground/protocol/openid-connect/auth?response_type=code&client_id=meyer-meyer-oidc-test&redirect_uri=' + encodeURIComponent('https://yard.mo-apps.de') + '&state=' + date, '_self');
// }

await auth.authenticate();


const home = 'home';

const router = new createRouter({
    routes: [
        {
            path: "/",
            redirect: home
        },

        {
            path: "/:pathMatch(.*)*",
            redirect: home
        },

        {
            path: "/home",
            name: "home",
            meta: {
                requiresAuth: true,
                layout: defaultLayout
            },
            component: loadView("home/home")
        },
        {
            path: "/authentication",
            name: "login-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Anmeldung"
            },
            component: loadView("authentication/login-form")
        },
        {
            path: "/createAccount",
            name: "create-account-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Zugang beantragen"
            },
            component: loadView("authentication/create-account/create-account-form")
        },
        {
            path: "/resetPassword",
            name: "reset-password-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Kennwort zurücksetzen"
            },
            component: loadView("authentication/reset-password/reset-password-form")
        },
        {
            path: "/changePassword",
            name: "change-password-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Kennwort ändern"
            },
            component: loadView("authentication/change-password-form")
        },
        {
            path: "/reporting/designer",
            name: "reporting/designer",
            meta: {
                requiresAuth: true,
                layout: simpleLayout
            },
            component: loadView("reporting/designer")
        },
        {
            path: "/assets/system-details",
            name: "assets/system-details",
            meta: {
                requiresAuth: true,
                layout: defaultLayout
            },
            component: loadView("assets/system-details")
        }
    ],
    history: createWebHashHistory()
});

if (appConfig.menu)
    addRouteFromMenuItem(appConfig.menu);

router.beforeEach((to, from, next) => {

    if (from.name === 'login-form')
        addRouteFromMenuItem(appConfig.menu);

    if (to.name === "login-form" && auth.loggedIn()) {
        next({name: "home"});
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!auth.loggedIn()) {
            next({
                name: "login-form",
                query: {redirect: to.fullPath}
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
